import React, { useContext, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import { breakpoints } from '../../utils/styles'

const Cart = () => {
  const {
    store: { checkout }
  } = useContext(StoreContext)

  const [totalQuantity, setTotalQuantity] = useState(0)

  const handleCheckout = () => {
    window.location.replace(checkout.webUrl)
  }

  useEffect(() => {
    if (checkout.lineItems) {
      return setTotalQuantity(
        checkout.lineItems.reduce(
          (accumulateur, valeurCourante) =>
            accumulateur + valeurCourante.quantity,
          0
        )
      )
    }
  }, [checkout.lineItems])

  const lineItems = checkout.lineItems.map(item => {
    return <LineItem key={item.id.toString()} item={item} />
  })

  // console.log('line-item:', checkout.lineItems)

  return (
    <CartWrapper>
      {checkout.lineItems.length === 0
        ? (
          <EmptyCartMsg>Votre panier est vide</EmptyCartMsg>
          )
        : (
          <ProductWrapper>
            <Title>Produits</Title>
            <Separator />
            {lineItems}
            <CheckoutWrapper>
              <TotalPriceBox>
                Sous-total ({totalQuantity}{' '}
                {`article${totalQuantity === 1 ? '' : 's'}`}):{' '}
                <TotalPrice>{checkout.totalPrice} €</TotalPrice>
              </TotalPriceBox>
              <Taxes>Taxes incluses</Taxes>
            </CheckoutWrapper>
          </ProductWrapper>
          )}
      <ButtonBox show={checkout.lineItems.length !== 0}>

        <Link to='/e-shop'>
          <CartButton style={{ fontSize: '0.9em' }}>Retour a la boutique</CartButton>
        </Link>

        {checkout.lineItems.length === 0
          ? null
          : (
            <CartButton
              onClick={handleCheckout}
              disabled={checkout.lineItems.length === 0}
              checkout
            >
              Commander
            </CartButton>
            )}
      </ButtonBox>
    </CartWrapper>
  )
}

export default Cart

export const CartWrapper = styled.div`
  margin: 3em 0 7em 0;
`

export const ProductWrapper = styled.div`
  padding: 0 2em 0 2em;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0 1em 0 1em;
  }
`

export const Title = styled.p`
  color: #2b5c6b;
  font-size: 1.6em;
  margin: 0.3em 0;
`

export const Separator = styled.div`
  border-bottom: 1px solid grey;
  width: 100%;
`

export const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 2em 0;

  @media (max-width: ${breakpoints.l}px) {
    align-items: flex-end;
    padding: 0 1em;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  justify-content: ${props => (props.show ? 'space-between' : 'center')};
  flex-direction: row;
  margin-top: 4em;

  @media (max-width: ${breakpoints.l}px) {
    margin: 0 1em;
  }

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column-reverse;
  }
`

export const EmptyCartMsg = styled.p`
  display: block;
  text-align: center;
  margin: 0 1em 4em;
`

export const TotalPriceBox = styled.p`
  font-size: 1.4em;
  margin: 0 1em 0 0;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.2em;
    text-align: right;
  }
`

export const TotalPrice = styled.span`
  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.2em;
  }
`

export const Taxes = styled.p`
  margin: 0 4em 0 0;
  font-size: 0.8em;
  font-family: 'Avenir Light';

  @media (max-width: ${breakpoints.s}px) {
    margin-right: 3.5em;
  }
`

export const CartButton = styled.button`
  background-color: ${props => (props.checkout ? '#8cc63f' : '#2b5c6b')};
  border-radius: 30px;
  height: 2.2em;
  color: ${props => (props.checkout ? 'white' : '#eed7b8')};
  font-size: 0.9em;
  font-weight: 400;
  padding: 0 ${props => (props.checkout ? '4em' : '1em')};
  border: 2px solid transparent;
  font-family: 'Krona One', serif;
  text-align: center;
  text-decoration: none;

  @media (max-width: ${breakpoints.s}px) {
    display: block;
    width: 80%;
    margin: 0 auto 1em auto;
    font-size: 1.2em;
    padding: 0;
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: white;
      border: 2px solid ${props => (props.checkout ? '#8cc63f' : '#2b5c6b')};
      color: ${props => (props.checkout ? '#8cc63f' : '#2b5c6b')};
    }
  }
`
