import React from 'react'
import SEO from './../components/seo'
import ProductHeader from '../templates/ProductPage/ProductHeader'
import Cart from '~/components/Cart'
import { Container } from '~/utils/styles'

const CartPage = () => (
  <>
    <SEO title="Panier" description="Page panier" />
    <ProductHeader title='Votre panier' />
    <Container>
      <Cart />
    </Container>
  </>
)

export default CartPage
