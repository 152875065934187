import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'
import StoreContext from '~/context/StoreContext'
import deleteCross from '../../../data/icons/cross-cart.png'
import selectArrow from '../../../data/icons/select-arrow.png'

const LineItem = props => {
  const { item } = props
  const {
    removeLineItem,
    updateLineItem,
    store: { client, checkout }
  } = useContext(StoreContext)

  const variantImage =
    item.variant && item.variant.image
      ? (
        <img src={item.variant.image.src} alt={`${item.title}`} height='220px' />
        )
      : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
  }

  const handleChangeQuantity = e => {
    const { value } = e.target
    updateLineItem(client, checkout.id, item.id, value)
  }

  const titles = item.title.split(' - ')

  const quantityNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  return (
    <>
      <Wrapper>
        <DeleteCross src={deleteCross} onClick={handleRemove} />
        <ProductImgBox to={`/e-shop/${item.variant.product.handle}/`}>
          <DeleteCrossImg src={deleteCross} onClick={handleRemove} />
          {variantImage}
        </ProductImgBox>
        <ProductContainer>
          <ProductTitleBox>
            <ProductTitle to={`/e-shop/${item.variant.product.handle}#product`}>
              {titles[0]}
            </ProductTitle>
            <ProductSubTitle>{titles[1]}</ProductSubTitle>
            {item.variant.selectedOptions.length !== 1 ? <Separator /> : null}
            {item.variant.selectedOptions &&
              item.variant.selectedOptions.map((option, index) => {
                if (option.name === 'Title') {
                  return
                }
                return (
                  <ProductOption key={`productOption-${index}`}>
                    {option.name} <span>{option.value}</span>
                  </ProductOption>
                )
              })}
          </ProductTitleBox>
          {/* {item.variant.title === !'Default Title' ? item.variant.title : ''} */}
          {/* {selectedOptions} */}
          <ProductForm>
            <p
              style={{
                fontSize: '0.6em',
                margin: '0 0 0 1em',
                fontFamily: 'Avenir Light'
              }}
            >
              Quantité
            </p>
            <QuantitySelector
              arrow={selectArrow}
              onChange={handleChangeQuantity}
            >
              {quantityNumber.map(number => {
                return (
                  <option
                    key={`quantity${number}`}
                    selected={number === item.quantity}
                  >
                    0{number}
                  </option>
                )
              })}
            </QuantitySelector>
            <p style={{ fontSize: '0.8em', margin: '0' }}>Prix unitaire</p>
            <p style={{ fontSize: '1.7em', margin: '0' }}>
              {item.variant.price} €
            </p>
            <p style={{ fontSize: '0.5em', margin: '0' }}>Taxes incluses</p>
          </ProductForm>
        </ProductContainer>
      </Wrapper>
    </>
  )
}

export default LineItem

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1em 1em;
  border-bottom: 1px solid grey;
  margin: 2em 0;

  @media (max-width: ${breakpoints.l}px) {
    padding-bottom: 2em;
    margin-bottom: 2em;
  }
`

export const ProductImgBox = styled.div`
  position: relative;
  z-index: 0;
`

export const DeleteCrossImg = styled.img`
  position: absolute;
  right: -9px;
  top: -9px;
  width: 1.8em;
  cursor: pointer;
  z-index: 30;

  @media (min-width: ${breakpoints.s}px) {
    display: none;
  }
`

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1em;
  }
`

export const ProductTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    margin-left: 1.5em;
  }
`

export const ProductTitle = styled(Link)`
  font-size: 1.7em;
  margin: 0.1em 0 0 0;
  text-decoration: none;
  color: black;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1em;
  }
`

export const ProductSubTitle = styled.p`
  margin: 0 0 0.8em;
  font-size: 0.8em;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 0.6em;
  }
`

export const Separator = styled.div`
  border-bottom: 1.3px solid black;
  width: 6em;
  margin-left: 0.2em;
  margin-bottom: 0.6em;
`

export const ProductOption = styled.p`
  font-family: 'Avenir Light';
  font-size: 0.9em;
  margin: 0.3em 0;

  span {
    font-weight: bold;
  }
`

export const QuantitySelector = styled.select`
  width: 4em;
  height: 1.8em;
  border-radius: 20px;
  border: 2px solid #2b5c6b;
  text-align: left;
  background-color: white;
  padding-left: 0.8em;
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 0.9em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('${props => props.arrow}');
  background-repeat: no-repeat;
  background-position: 85% center;
  background-size: 20px;
  outline: none;
  cursor: pointer;

  @media (max-width: ${breakpoints.s}px) {
    margin-left: 0;
  }
`

export const ProductForm = styled.div`
  font-size: 1em;
  width: 10em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 1em;
    margin-left: 1em;
    width: 100%;
  }
`

export const ProductQuantity = styled.span`
  font-size: 0.8em;
  margin-left: 0.7em;
`

export const DeleteCross = styled.img`
  width: 2em;
  margin: 0.6em 1em 0 0;
  cursor: pointer;
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
